import React, {useEffect, useState} from "react"
import _ from "lodash"
import qs from "qs"
import {AppSync} from "../../libraries/appsync"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageTitle from "../../components/PageTitle"
import NewsList from "../../components/NewsList"
import PageNavigation from "../../components/PageNavigation"
import Pagination from "../../components/Pagination"
import {ButtonTop} from "../../components/Button"
import {Preview, cryptString} from "../../libraries/preview";
import NewsTagList from "../../components/NewsTagList"

export default ({location}) => {
  const [loading, setLoading] = useState(true)
  const [tag, setTag] = useState(false)
  const [news, setNews] = useState([])
  const [tags, setTags] = useState([])
  const queryString = qs.parse(_.get(location, "search"), {ignoreQueryPrefix: true})

  useEffect(() => {
    setLoading(true)

    Preview(cryptString(location)).then(customDateTime => {
      //const newsQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query listNews {
            listNews {
              site_c
              news {
                site_c
                srvc_tc
                news_id
                title
                article
                stt_ts
                end_ts
                tags
                thumbnail_url
                dsptgt_tc
                tgtmember_tc
              }
            }
          }
        `
      }).then(res => {
        const tag = _.get(queryString, "tag", false)
        setTag(tag)
        let news = _.get(res.data, "data.listNews.news", [])
        let tags = []
        for (const newsIndex in news) {
          const newsObj = news[newsIndex]
          if (newsObj.tags !== undefined && newsObj.tags !== null) {
            const newsObjTags = newsObj.tags.split(',')
            tags = _.union(tags, newsObjTags).sort((x, y) => x.localeCompare(y, 'ja'))
          }
        }
        setTags(tags)
        if (tag) {
          news = news.filter(obj => obj.tags !== null && obj.tags.includes(tag))
        }
        setNews(news)
        setLoading(false)
      })
    })
  }, [])

  const initialPage = 1
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(initialPage)
  const pageChangeHandler = p => setCurrentPage(p)

  return (
    <Layout>
      <SEO title="ニュース一覧"/>
      <section className="inner">
        <PageTitle>
          { (tag) ? `#${tag}` : 'NEWS' }
        </PageTitle>
        <NewsList items={news.slice((currentPage - 1) * pageSize, currentPage * pageSize)} loading={loading}/>
        <Pagination items={news} initialPage={initialPage} pageSize={pageSize} changeHandler={pageChangeHandler}/>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
        <NewsTagList tags={tags} />
      </section>
    </Layout>
  )
}
